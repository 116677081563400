body {
  overscroll-behavior: contain;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.toBePlayed {
  background-color: #bbb;
}

.win {
  background-color: rgb(59, 253, 0);
}

.loose {
  background-color: rgb(255, 0, 0);
}

.gameStats {
  z-index: 100;
  background: white;
  width: 400px;
  height: 100vh;
}

.gameField {
  overflow: hidden;
  height: 100vh;
}

.background {
  height: 100vh;
  background: url(bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#lines .line {
  fill: none;
  stroke-width: 2;
}
#stations .station {
  fill: none;
  stroke-width: 5;
}
#stations .station.interchange {
  fill: #fff;
  stroke: #000;
  stroke-width: 0.5;
}
@keyframes blink {
  100%,
  0% {
    fill: #f00;
    stroke: #f00;
  }
  60% {
    fill: #000;
    stroke: #000;
  }
}

#stations .station.lastStation {
  animation: blink 0.8s infinite;
}

#lines .line.S1,
#stations .station.S1 {
  stroke: #de4da4;
}
#stations .station.interchange.S1 {
  stroke: #000;
}
#lines .line.S2,
#stations .station.S2 {
  stroke: #005f27;
}
#stations .station.interchange.S2 {
  stroke: #000;
}
#lines .line.S25,
#stations .station.S25 {
  stroke: #005f27;
}
#stations .station.interchange.S25 {
  stroke: #000;
}
#lines .line.S3,
#stations .station.S3 {
  stroke: #0a4c99;
}
#stations .station.interchange.S3 {
  stroke: #000;
}
#lines .line.S5,
#stations .station.S5 {
  stroke: #ff5900;
}
#stations .station.interchange.S5 {
  stroke: #000;
}
#lines .line.S7,
#stations .station.S7 {
  stroke: #6f4e9c;
}
#stations .station.interchange.S7 {
  stroke: #000;
}
#lines .line.S75,
#stations .station.S75 {
  stroke: #6f4e9c;
}
#stations .station.interchange.S75 {
  stroke: #000;
}
#lines .line.S85,
#stations .station.S85 {
  stroke: #55a822;
}
#stations .station.interchange.S85 {
  stroke: #000;
}
#lines .line.S8,
#stations .station.S8 {
  stroke: #55a822;
}
#stations .station.interchange.S8 {
  stroke: #000;
}
#lines .line.S9,
#stations .station.S9 {
  stroke: #8a0e30;
}
#stations .station.interchange.S9 {
  stroke: #000;
}
#lines .line.S41,
#stations .station.S41 {
  stroke: #a23b1e;
}
#stations .station.interchange.S41 {
  stroke: #000;
}
#lines .line.S42,
#stations .station.S42 {
  stroke: #c26a36;
}
#stations .station.interchange.S42 {
  stroke: #000;
}
#lines .line.S45,
#stations .station.S45 {
  stroke: #c38737;
}
#stations .station.interchange.S45 {
  stroke: #000;
}
#lines .line.S46,
#stations .station.S46 {
  stroke: #c38737;
}
#stations .station.interchange.S46 {
  stroke: #000;
}
#lines .line.S47,
#stations .station.S47 {
  stroke: #c38737;
}
#stations .station.interchange.S47 {
  stroke: #000;
}
#lines .line.U1,
#stations .station.U1 {
  stroke: #55a822;
}
#stations .station.interchange.U1 {
  stroke: #000;
}
#lines .line.U3,
#stations .station.U3 {
  stroke: #019377;
}
#stations .station.interchange.U3 {
  stroke: #000;
}
#lines .line.U2,
#stations .station.U2 {
  stroke: #ff3300;
}
#stations .station.interchange.U2 {
  stroke: #000;
}
#lines .line.U4,
#stations .station.U4 {
  stroke: #ffd900;
}
#stations .station.interchange.U4 {
  stroke: #000;
}
#lines .line.U5,
#stations .station.U5 {
  stroke: #672f17;
}
#stations .station.interchange.U5 {
  stroke: #000;
}
#lines .line.U55,
#stations .station.U55 {
  stroke: #672f17;
}
#stations .station.interchange.U55 {
  stroke: #000;
}
#lines .line.U6,
#stations .station.U6 {
  stroke: #6f4e9c;
}
#stations .station.interchange.U6 {
  stroke: #000;
}
#lines .line.U7,
#stations .station.U7 {
  stroke: #3690c0;
}
#stations .station.interchange.U7 {
  stroke: #000;
}
#lines .line.U8,
#stations .station.U8 {
  stroke: #0a3c85;
}
#stations .station.interchange.U8 {
  stroke: #000;
}
#lines .line.U9,
#stations .station.U9 {
  stroke: #ff7300;
}
#stations .station.interchange.U9 {
  stroke: #000;
}
